import { Box, Button, css, Stack, styled, Theme, ThemeProvider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { Layout } from 'plotly.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import logoBmm from '@/assets/icons/bmm-logo.svg?url';
import ArrowIcon from '@/assets/icons/form/dropdown.svg?react';
import logoIvm from '@/assets/icons/ivm-logo.png';
import logoSmm from '@/assets/icons/smm-logo.svg?url';
import backgroundImage from '@/assets/images/shutterstock.jpg';
import { ActiveInactivePlot } from '@/monitoring/plots/ActiveInactivePlot';
import { CarbonDioxidePlot } from '@/monitoring/plots/CarbonDioxidePlot';
import { DistancePlot } from '@/monitoring/plots/DistancePlot';
import { ModalSplitPlot } from '@/monitoring/plots/ModalSplitPlot';
import { QueryKeys } from '@/services/QueryKeys';
import { MonitoringService, MonitoringStyle } from '@/services/api';
import { themeIvm, themeSmm } from '@/setup/theme';
import { transientOptions } from '@/utils/transientOptions';

const Root = styled(
  Box,
  transientOptions,
)<{ $backgroundImage: string }>(
  ({ theme, $backgroundImage }) => css`
    position: relative;
    min-height: 100vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${$backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.spacing(6)};
    padding-bottom: ${theme.spacing(6)};

    ${theme.breakpoints.up('sm')} {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
    }
  `,
);

const CardTitle = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(2)} ${theme.spacing(2)} 0 0;
    padding: ${theme.spacing(2)};
    color: ${theme.palette.primary.contrastText};
    justify-content: space-between;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      display: flex;
      padding: ${theme.spacing(3)} ${theme.spacing(4)};
    }
  `,
) as typeof Box;

const Wrapper = styled(Box)(
  ({ theme }) => css`
    background-color: rgba(255, 255, 255, 0.9);
    width: 90%;
    height: 90vh;
    border-radius: ${theme.spacing(2)};
  `,
);

const Carousel = styled(Box)(
  () => css`
    --duration: 1000ms;
    display: block;
    overflow: hidden;
    height: 100%;
    position: relative;
  `,
);

const CarouselItem = styled(Box)(
  () => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.enter {
      &.positive {
        transform: translateX(100%);
      }
      &.opposite {
        transform: translateX(-100%);
      }
    }
    &.enter-active {
      transition: transform var(--duration) ease-in-out;
      &.positive,
      &.opposite {
        transform: translateX(0px);
      }
    }

    &.exit {
      transform: translateX(0px);
    }

    &.exit-active {
      transition: transform var(--duration) ease-in-out;
      &.positive {
        transform: translateX(-100%);
      }
      &.opposite {
        transform: translateX(100%);
      }
    }
  `,
);

const Indicator = styled(Button)(
  () => css`
    background-color: white;
    height: 10px;
    min-width: 10px;
    padding: 0;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0;
    cursor: pointer;
  `,
);

const PLOT_LAYOUT: Partial<Layout> = {
  paper_bgcolor: 'rgba(0,0,0,0)',
  plot_bgcolor: 'rgba(0,0,0,0)',
  modebar: { bgcolor: 'rgba(0,0,0,0)', color: 'lightgrey', activecolor: 'grey' },
  font: { size: 16 },
};
const SHOW_ICONS = true;
const AUTO_SLIDER_INTERVAL = 15000;
const MIN_SAMPLE_SIZE = 10;

export function ShareAnalysisPage() {
  const { t } = useTranslation();
  const { sharedAnalysisId } = useParams();
  const [slide, setSlide] = useState(0);
  const [lastUpdate, setLastUpdate] = useState<Dayjs>();
  const [direction, setDirection] = useState<'positive' | 'opposite'>('positive');

  const { data: location } = useQuery({
    queryKey: QueryKeys.locations.id(sharedAnalysisId as string),
    queryFn: () => MonitoringService.findOne({ monitoringId: sharedAnalysisId as string }),
    enabled: !!sharedAnalysisId,
  });

  const { data, status } = useQuery({
    queryKey: QueryKeys.monitoring.id(sharedAnalysisId!),
    queryFn: () => MonitoringService.sharedAnalysis({ sharedAnalysisId: sharedAnalysisId! }),
    refetchOnWindowFocus: false,
    refetchInterval: 3 * AUTO_SLIDER_INTERVAL,
    enabled: !!sharedAnalysisId,
  });

  useEffect(() => {
    if (data) setLastUpdate(dayjs());
  }, [data]);

  const monitoringDataSize = useMemo(() => data?.map((commuteTypeData) => commuteTypeData.data).flat().length, [data]);

  const slides = useMemo(
    () =>
      data
        ? [
            {
              id: 'slide1',
              content: (
                <Stack direction="row" gap={0} sx={{ width: '100%', height: '100%' }}>
                  <ModalSplitPlot
                    data={data}
                    disableDownloadButton
                    showSubtitle
                    layout={{ ...PLOT_LAYOUT, margin: { l: 15, r: 15, b: 15, t: 140 } }}
                    centerLegend
                  />
                  <ActiveInactivePlot
                    data={data}
                    disableDownloadButton
                    showSubtitle
                    layout={{ ...PLOT_LAYOUT, margin: { l: 15, r: 15, b: 15, t: 140 } }}
                  />
                </Stack>
              ),
            },
            {
              id: 'slide2',
              content: (
                <DistancePlot
                  data={data}
                  disableDownloadButton
                  showIcons={SHOW_ICONS}
                  includeWayBack={false}
                  layout={{ ...PLOT_LAYOUT, margin: { b: 40, t: 140 } }}
                  hideBoxPoints
                  iconSizeFactor={1.25}
                  showSubtitle
                />
              ),
            },
            ...(data.find((item) => item.carbonDioxide > 0)
              ? [
                  {
                    id: 'slide3',
                    content: (
                      <CarbonDioxidePlot
                        data={data}
                        disableDownloadButton
                        showIcons={SHOW_ICONS}
                        layout={{ ...PLOT_LAYOUT, margin: { r: 0, b: 120, t: 140 } }}
                        iconSizeFactor={1}
                        showSubtitle
                      />
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [data],
  );

  const nextSlide = () => {
    setDirection('positive');
    setSlide(slide === slides.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setDirection('opposite');
    setSlide(slide === 0 ? slides.length - 1 : slide - 1);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, AUTO_SLIDER_INTERVAL);

    return () => clearInterval(interval);
  });

  return (
    data &&
    location &&
    lastUpdate && (
      <ThemeProvider
        theme={
          {
            [MonitoringStyle.SMM]: themeSmm,
            [MonitoringStyle.IVM]: themeIvm,
            [MonitoringStyle.BMM]: (theme: Theme) => theme,
          }[location.monitoringStyle]
        }
      >
        <Root $backgroundImage={backgroundImage}>
          <Wrapper>
            <Stack sx={{ height: '100%', position: 'relative' }}>
              <Box
                component="img"
                src={
                  {
                    [MonitoringStyle.BMM]: logoBmm,
                    [MonitoringStyle.SMM]: logoSmm,
                    [MonitoringStyle.IVM]: logoIvm,
                  }[location.monitoringStyle]
                }
                alt={
                  {
                    [MonitoringStyle.BMM]: 'Besser zur Arbeit',
                    [MonitoringStyle.SMM]: 'Besser zur Schule',
                    [MonitoringStyle.IVM]: 'ivm RheinMain',
                  }[location.monitoringStyle]
                }
                sx={{
                  width: 'auto',
                  height: location.monitoringStyle === MonitoringStyle.IVM ? 74 : 64,
                  position: 'absolute',
                  bottom: location.monitoringStyle === MonitoringStyle.IVM ? 10 : 16,
                  left: location.monitoringStyle === MonitoringStyle.IVM ? 10 : 16,
                }}
              />
              <CardTitle>
                <Typography variant="h2" component="h1">
                  {location.shareAnalysisTitle}
                </Typography>
                <Typography>{location.name}</Typography>
              </CardTitle>

              <Stack alignItems="center" sx={{ flex: 1 }} justifyContent="space-between" gap={2}>
                {status === 'error' ? (
                  <Stack flex={1} alignItems="center" justifyContent="center">
                    <Typography>Analyse akuell nicht verfügbar</Typography>
                  </Stack>
                ) : (
                  <>
                    {monitoringDataSize !== undefined && monitoringDataSize < MIN_SAMPLE_SIZE ? (
                      <Stack flex={1} alignItems="center" justifyContent="center">
                        <Typography variant="h2" gutterBottom>
                          Mobilitäts-Dashboard
                        </Typography>
                        <Typography>Die minimale Stichprobengröße wurde noch nicht erreicht.</Typography>
                        <Typography>
                          Das Dashboard aktualisiert sich automatisch, sobald die minimale Anzahl überschritten wird.
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack direction="row" sx={{ width: '100%', flex: 1 }}>
                        <Button onClick={prevSlide}>
                          <ArrowIcon style={{ transform: 'rotate(90deg)' }} />
                        </Button>
                        <Box sx={{ flex: 1, overflow: 'hidden' }}>
                          <Carousel>
                            {slides.map((item, index) => (
                              <CSSTransition key={item.id} in={index === slide} timeout={1000} unmountOnExit>
                                <CarouselItem className={direction}>{item.content}</CarouselItem>
                              </CSSTransition>
                            ))}
                          </Carousel>
                        </Box>
                        <Button onClick={nextSlide}>
                          <ArrowIcon style={{ transform: 'rotate(-90deg)' }} />
                        </Button>
                      </Stack>
                    )}

                    <Stack alignItems="center">
                      <Typography fontWeight="700">{`Die Ergebnisse aus: "${location.monitoringTitle}"`}</Typography>
                      <Typography>{`Letzte Aktualisierung: ${lastUpdate.format('lll')}`}</Typography>
                      <Typography>{`Stichprobengröße: ${monitoringDataSize} | Zeitspanne: ${t(`ShareAnalysisPeriod.${location.shareAnalysisPeriod}`)}`}</Typography>
                      <Stack direction="row" gap={2} sx={{ padding: 2 }}>
                        {slides.map((item, index) => (
                          <Indicator
                            key={item.id}
                            onClick={() => {
                              if (index > slide) setDirection('positive');
                              else setDirection('opposite');
                              setSlide(index);
                            }}
                            sx={{ ...(slide !== index && { backgroundColor: 'lightgrey' }) }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          </Wrapper>
        </Root>
      </ThemeProvider>
    )
  );
}
